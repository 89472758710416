html,
body {
  margin: 0;
  padding: 0;
  font-family: "M PLUS 1p", sans-serif !important;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

#address-input .pac-container {
  margin-top: -210px;
}

/* creates part of the curve */
.bubble-left:before {
  content: "";
  position: absolute;
  margin-left: 0.5rem;
  z-index: -1;
  bottom: 0px;
  left: -20px;
  height: 24px;
  border-right: 20px solid #DBE3F7;
  background: #DBE3F7;
  /* css3 */
  -webkit-border-bottom-right-radius: 80px 50px;
  -moz-border-radius-bottomright: 80px 50px;
  border-bottom-right-radius: 80px 50px;
  /* using translate to avoid undesired appearance in CSS2.1-capabable but CSS3-incapable browsers */
  -webkit-transform: translate(0, -2px);
  -moz-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.bubble-left {
  display: inline-block;
  position: relative;
}

/* creates part of the curved pointy bit */
.bubble-left:after {
  content: "";
  position: absolute;
  margin-left: 0.5rem;
  z-index: -1;
  bottom: 0px;
  left: -20px;
  width: 24px;
  height: 24px;
  background: #fff;
  /* css3 */
  -webkit-border-bottom-right-radius: 40px 50px;
  -moz-border-radius-bottomright: 40px 50px;
  border-bottom-right-radius: 40px 50px;
  /* using translate to avoid undesired appearance in CSS2.1-capabable but CSS3-incapable browsers */
  -webkit-transform: translate(-10px, -2px);
  -moz-transform: translate(-10px, -2px);
  -ms-transform: translate(-10px, -2px);
  -o-transform: translate(-10px, -2px);
  transform: translate(-10px, -2px);
}

/* creates part of the curve */
.bubble-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0px;
  right: -10px;
  height: 24px;
  border-left: 20px solid #c5eafd;
  background: #c5eafd;
  /* css3 */
  -webkit-border-bottom-left-radius: 80px 50px;
  -moz-border-radius-bottomleft: 80px 50px;
  border-bottom-left-radius: 80px 50px;
  /* using translate to avoid undesired appearance in CSS2.1-capabable but CSS3-incapable browsers */
  -webkit-transform: translate(0, -2px);
  -moz-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.bubble-right {
  display: inline-block;
  position: relative;
}

/* creates part of the curved pointy bit */
.bubble-right:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0px;
  right: -30px;
  width: 24px;
  height: 24px;
  background: #fff;
  /* css3 */
  -webkit-border-bottom-left-radius: 40px 50px;
  -moz-border-radius-bottomleft: 40px 50px;
  border-bottom-left-radius: 40px 50px;
  /* using translate to avoid undesired appearance in CSS2.1-capabable but CSS3-incapable browsers */
  -webkit-transform: translate(-10px, -2px);
  -moz-transform: translate(-10px, -2px);
  -ms-transform: translate(-10px, -2px);
  -o-transform: translate(-10px, -2px);
  transform: translate(-10px, -2px);
}

/* PlaceCard image slider prev/next buttons */
.slick-prev {
  margin-left: 30px;
  z-index: 1;
}

.slick-next {
  margin-right: 30px;
  z-index: 1;
}
    .auto-complete-filter{
      position: sticky !important;
    top: 10px;
    width: 100%;
    padding: 0 15px !important;
    z-index: 1;
    }

    .auto-complete-filter  .ant-select .ant-input {
      border-radius: 0 !important;
    }

    .auto-complete-filter .ant-select-search__field__mirror {
      display: none !important;
    }

@media screen and (max-width: 376px)
{
.option-panel{
  max-width: 370px;
  padding-left: 30px !important;

}
}
@media screen and (max-width: 321px)
{
.option-panel{
  max-width: 315px;  
  padding-left: 80px !important;
}
}

@media screen and (max-height: 220px)
{
  .header{
    height: 0;
    padding: 0 !important;
  }
}